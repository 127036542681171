import React from "react";
import { Amplify } from 'aws-amplify';
import awsExports from './aws-exports';
import { BrowserRouter as Router, Routes, Route, Navigate } from "react-router-dom";
import ChatPage from "./pages/chatpage";
import { Authenticator } from '@aws-amplify/ui-react';
import '@aws-amplify/ui-react/styles.css';
import './App.css';

// Configure Amplify
Amplify.configure(awsExports);

function App() {
  return (
    <Router>
      <Authenticator>
        <Routes>
          <Route path="/" element={<Navigate to="/chat" />} /> {/* This will redirect authenticated users to /chat */}
          <Route path="/chat" element={<ChatPage />} />
          {/* Other routes you might want that are outside the authenticator can be added here */}
        </Routes>
      </Authenticator>
    </Router>
  );
}

export default App;
